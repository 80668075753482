<template>
  <div class="line-item-v2">
    <div class="d-flex mb-2 align-center justify-space-between">
      <div class="d-flex">
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            @click="addParent"
            style="height: 32px !important"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_component()"
            style="height: 32px !important"
          >
            Component
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div class="custom-grey-border">
      <div class="scrollbar-wrapper">
        <div class="scrollbar" ref="topScrollbar" @scroll="syncScrollFromTop">
          <div class="scroll-content"></div>
        </div>
      </div>
      <!-- Bottom scrollbar -->
      <div
        class="content-wrapper"
        ref="scrollContainer"
        style="max-width: 2697px; overflow: scroll; display: grid"
        @scroll="syncScrollFromBottom"
      >
        <div class="d-flex grey lighten-4">
          <div
            class="color-custom-blue py-2 font-weight-500 text-center"
            style="min-width: 30px"
          ></div>
          <div
            class="color-custom-blue py-2 px-2 font-weight-500 text-left"
            style="min-width: 205px"
          >
            Brand
          </div>

          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 205px"
          >
            Model
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 205px"
          >
            Part
          </div>
          <div
            class="color-custom-blue py-2 px-2 font-weight-500 text-left"
            style="min-width: 116px"
          >
            Product Type
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            Currency
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            Cost
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            FLT
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            XR
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            GEAR
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            LAMP
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            ACC
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            UNIT COST
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            MARKUP %
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            UNIT SELLING
          </div>

          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            REQ. QTY
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            PRO. QTY
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            UOM
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            PRO. UNIT SELL.
          </div>

          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            TOTAL COST
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            TOTAL SELLING
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500 px-2"
            style="min-width: 116px"
          >
            % TOTAL SALES
          </div>
          <div
            class="color-custom-blue py-2 font-weight-500"
            style="min-width: 80px"
          ></div>
        </div>
        <!-- Loop through each parent item (header) -->

        <Draggable
          tag="ul"
          v-model="selected_line_items2"
          class="line-item-drag-group mb-0"
          draggable=".line-item-drag"
          handle=".line-item-drag-icon"
          ghost-class="line-item-ghost"
          group="parent-group"
          v-on:start="drag = true"
          v-on:end="drag = false"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <li
              v-for="(parent, parentIndex) in selected_line_items2"
              :key="`line-item-drag-${parentIndex}`"
              class="line-item-drag px-0 mb-1"
              :class="parent?.children?.length == 0 ? 'header_color' : ''"
            >
              <!-- Parent (Header) Row -->
              <div
                class="d-flex my-0 align-items-center pt-1 blue lighten-5 product_header"
              >
                <div
                  class="font-weight-600 text-center freeze-column"
                  style="min-width: 30px"
                >
                  <v-icon
                    class="line-item-drag-icon cursor-move"
                    color="color-custom-blue"
                    >mdi-drag</v-icon
                  >
                </div>
                <div class="text-left px-0" style="width: 97%">
                  <v-text-field
                    v-model="parent.group_name"
                    class="line-item-header-text px-0"
                    placeholder="Group Name"
                    dense
                    filled
                    solo
                    flat
                    color="cyan"
                  />
                </div>
                <div class="last-child" style="min-width: 40px">
                  <!-- Button to remove parent -->
                  <v-btn icon @click="openDeleteConfirmation(parentIndex)">
                    <v-icon color="red">mdi-delete</v-icon>
                  </v-btn>
                  <!-- Button to add a new child -->
                  <!-- <v-btn icon @click="addChild(parentIndex)">
                    <v-icon color="green">mdi-plus</v-icon>
                  </v-btn> -->
                </div>
              </div>

              <!-- Loop through each child item under the parent -->
              <Draggable
                tag="ul"
                v-model="parent.children"
                class="line-item-drag-group mb-0"
                draggable=".line-item-drag"
                handle=".line-item-drag-icon"
                ghost-class="line-item-ghost"
                :group="`child-group-${parentIndex}`"
                v-on:start="drag = true"
                v-on:end="drag = false"
              >
                <transition-group
                  type="transition"
                  :name="!drag ? 'flip-list' : null"
                >
                  <li
                    v-for="(child, childIndex) in parent.children"
                    :key="`line-item-drag-${parentIndex}-${childIndex}`"
                    class="line-item-drag px-0"
                  >
                    <div class="d-flex my-0 align-items-center">
                      <div
                        class="font-weight-600 text-center freeze-column"
                        style="min-width: 30px"
                      >
                        <v-icon
                          class="line-item-drag-icon cursor-move"
                          color="color-custom-blue"
                          >mdi-drag</v-icon
                        >
                      </div>
                      <div
                        class="text-left px-1 freeze-column"
                        style="min-width: 200px"
                      >
                        <v-combobox
                          dense
                          color="cyan"
                          filled
                          v-model="child.brand"
                          :items="brandList"
                          placeholder="Select Brand"
                          solo
                          flat
                          hide-details
                        />
                      </div>

                      <div
                        class="text-left px-1 freeze-column"
                        style="min-width: 200px"
                      >
                        <v-combobox
                          dense
                          color="cyan"
                          filled
                          v-model="child.model"
                          :items="modelList"
                          placeholder="Select Model"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <div
                        class="text-left px-1 freeze-column"
                        style="min-width: 200px"
                      >
                        <v-combobox
                          dense
                          color="cyan"
                          filled
                          v-model="child.part"
                          :items="partList"
                          placeholder="Select Part"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <div class="text-left px-1 freeze-column">
                        <v-combobox
                          dense
                          color="cyan"
                          filled
                          v-model="child.product"
                          :items="productType"
                          placeholder="Select Product"
                          solo
                          flat
                          hide-details
                        />
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          id="costing-currency"
                          :items="currencyListType"
                          v-model.trim="child.cost_currency"
                          placeholder="Select Currency"
                          solo
                          flat
                          item-color="cyan"
                          item-text="display_name"
                          item-value="id"
                          hide-details
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No Currency Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-text-field
                          v-model="child.cost"
                          class="line-item-header-text px-0"
                          placeholder="Cost"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.flt"
                          class="line-item-header-text px-0"
                          placeholder="FLT"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.xr"
                          class="line-item-header-text px-0"
                          placeholder="XR"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.gear"
                          class="line-item-header-text px-0"
                          placeholder="GEAR"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.lamp"
                          class="line-item-header-text px-0"
                          placeholder="LAMP"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.acc"
                          class="line-item-header-text px-0"
                          placeholder="ACC"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-text-field
                          v-model="child.item_cost"
                          class="line-item-header-text px-0"
                          placeholder="Unit Cost"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          readonly
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.margin"
                          class="line-item-header-text px-0"
                          placeholder="Markup"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-text-field
                          v-model="child.unit_selling"
                          class="line-item-header-text px-0"
                          placeholder="Unit Selling"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          readonly
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.required_quantity"
                          class="line-item-header-text px-0"
                          placeholder="Required Quantity"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.proquantity"
                          class="line-item-header-text px-0"
                          placeholder="Quantity"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-autocomplete
                          dense
                          color="cyan"
                          filled
                          id="uom"
                          :items="uomListType"
                          v-model.trim="child.uom"
                          placeholder="UOM"
                          solo
                          flat
                          item-color="cyan"
                          item-text="display_name"
                          item-value="id"
                          hide-details
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-html="'No UOM Found.'"
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-autocomplete>
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-text-field
                          v-model="child.pro_unit_selling"
                          class="line-item-header-text px-0"
                          placeholder="Unit Selling"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          readonly
                        />
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-text-field
                          v-model="child.total_cost"
                          class="line-item-header-text px-0"
                          placeholder="Total Cost"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          readonly
                        />
                      </div>
                      <div class="text-left px-1 highlighted" style="">
                        <v-text-field
                          v-model="child.total_selling"
                          class="line-item-header-text px-0"
                          placeholder="Total Selling"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          readonly
                        />
                      </div>
                      <div class="text-left px-1" style="">
                        <v-text-field
                          v-model="child.total_sales"
                          class="line-item-header-text px-0"
                          placeholder="Total Sales"
                          dense
                          filled
                          solo
                          flat
                          color="cyan"
                          readonly
                        />
                      </div>
                      <div class="last-child" style="min-width: 80px">
                        <!-- Button to remove child -->
                        <v-btn
                          icon
                          @click="removeChild(parentIndex, childIndex)"
                        >
                          <v-icon color="red">mdi-delete</v-icon>
                        </v-btn>
                        <!-- Button to add a new child -->
                        <v-btn icon @click="addChild(parentIndex, childIndex)">
                          <v-icon color="green">mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </div>
                  </li>
                </transition-group>
              </Draggable>
              <div class="d-flex" v-if="parent?.children?.length > 0">
                <div style="width: 2165px" class=""></div>
                <div style="width: 116px" class="font-weight-600 text-h6">
                  Total
                </div>
                <div style="width: 116px" class="">
                  {{ parent.p_unit_selling }}
                </div>
                <div style="width: 116px" class="">
                  <!-- <v-text-field
                class="line-item-header-text px-0"
                placeholder="Total Cost"
                dense
                filled
                solo
                flat
                type="number"
                v-model="row.p_total_cost"
                color="cyan"
              /> -->
                  {{ parent.p_total_cost }}
                </div>
                <div style="width: 116px" class="font-weight-600 text-h6">
                  <!-- <v-text-field
                class="line-item-header-text px-0"
                placeholder="Total Cost"
                dense
                filled
                solo
                flat
                type="number"
                v-model="row.p_total_saling"
                color="cyan"
              /> -->
                  {{ parent.p_total_saling }}
                </div>
                <div style="width: 116px" class="">
                  <!-- <v-text-field
                class="line-item-header-text px-0"
                placeholder="Total Cost"
                dense
                filled
                solo
                flat
                v-model="row.p_total_sales"
                color="cyan"
              /> -->
                  {{ parent.p_total_sales }}
                </div>
              </div>
            </li>
          </transition-group>
        </Draggable>
        <template v-if="!selected_line_items2.length">
          <div class="custom-border-top p-3">
            <p
              class="m-0 p-4 font-size-16 font-weight-500 light-gray-background custom-grey-border d-flex align-center"
            >
              <v-icon class="mr-1">mdi-alert-circle-outline</v-icon>
              Oops... Nothing Found.
            </p>
          </div>
        </template>
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="d-flex">
        <v-btn-toggle color="cyan" group>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="header"
            @click="addParent"
            style="height: 32px !important"
          >
            Header
          </v-btn>
          <v-btn
            color="cyan"
            class="custom-bold-button"
            value="other"
            v-on:click="add_component()"
            style="height: 32px !important"
          >
            Component
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
    <div>
      <table width="100%" style="table-layout: fixed">
        <tbody>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              Total Selling
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              SGD387,880.00
              <!-- {{ formatMoney(subtotal) }} -->
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Total Cost Value
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              SGD240,949.82
              <!-- {{ formatMoney(subtotal) }} -->
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Total Margin
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              0.38
              <!-- {{ formatMoney(subtotal) }} -->
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Percentage Discount
            </td>
            <td width="15%" valign="middle" align="center">
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Discount"
                  dense
                  filled
                  solo
                  flat
                  v-model="discount_value"
                  v-on:keyup="calculate_total(), checkData()"
                  v-on:keypress="
                    limitDecimal($event, discount_value), checkData()
                  "
                  v-on:change="checkData()"
                  color="cyan"
                />

                <v-select
                  :items="discount_type_list"
                  v-model="discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                />
              </div>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              SGD0.00
              <!-- {{ formatMoney(discount) }} -->
            </td>
          </tr>
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Lump Sum Discount
            </td>
            <td width="15%" valign="middle" align="center">
              <div class="d-flex">
                <v-text-field
                  hide-details
                  class="line-item-header-text px-0"
                  placeholder="Lump Sum Discount"
                  dense
                  filled
                  solo
                  flat
                  v-model="lump_discount_value"
                  v-on:keyup="calculate_total(), checkData()"
                  v-on:keypress="
                    limitDecimal($event, lump_discount_value), checkData()
                  "
                  v-on:change="checkData()"
                  color="cyan"
                />

                <v-select
                  :items="discount_type_list"
                  v-model="lump_discount_type"
                  hide-details
                  item-text="type"
                  item-value="value"
                  item-color="cyan"
                  class="line-item-header-text discount-type text-h6 px-0"
                  placeholder="Type"
                  dense
                  filled
                  solo
                  flat
                  v-on:change="calculate_total()"
                  color="cyan"
                />
              </div>
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              SGD0.00
              <!-- {{ formatMoney(discount) }} -->
            </td>
          </tr>
          <!-- <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Tax [GST {{ gst_value }}%]
            </td>
            <td width="15%" valign="middle" align="center">
              <v-checkbox
                v-on:change="calculate_total()"
                v-model="tax_active"
                class="my-0 py-0 px-0 mx-0"
                color="cyan"
                :label="`Tax Amount: ${formatMoney(gst_amount)}`"
              />
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              {{ formatMoney(gst) }}
            </td>
          </tr> -->
          <tr>
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              Discounted Total Selling
            </td>
            <td width="15%" valign="middle" align="center">
              <!-- <v-text-field
                hide-details
                class="line-item-header-text px-0 line-item-adjustment"
                placeholder="Adjustment"
                dense
                filled
                solo
                flat
                v-model="adjustment"
                v-on:keyup="calculate_total()"
                v-on:keypress="limitDecimal($event, adjustment)"
                color="cyan"
              >
                <template v-slot:append>
                  <v-btn depressed v-on:click="auto_adjust()">Auto</v-btn>
                </template>
              </v-text-field> -->
            </td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600"
            >
              SGD387,880.00
              <!-- {{ formatMoney(adjustment) }} -->
            </td>
          </tr>
          <tr class="font-size-19">
            <td
              width="70%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              Grand Total
            </td>
            <td width="15%" valign="middle" align="center"></td>
            <td
              width="15%"
              valign="middle"
              align="right"
              class="font-weight-600 text-h5"
            >
              SGD387,880.00
              <!-- {{ formatMoney(total) }} -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Confirmation Dialog -->
    <v-dialog v-model="showDeleteConfirmation" max-width="600px">
      <DeleteSmallConfirm
        :message="'Are you sure you want to delete this item?'"
        @success="handleDelete"
        @cancel="handleCancel"
      />
    </v-dialog>
  </div>
</template>

<script>
import Draggable from "vuedraggable";
import DeleteSmallConfirm from "@/view/pages/partials/DeleteSmallConfirm";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "line-item-v1",
  data() {
    return {
      showDeleteConfirmation: false,
      drag: false,
      firstParentAdded: null,
      brandList: ["JRLite", "ELDOLED", "MEANWELL"],
      modelList: [
        "COVEFLEX1808",
        "AP-293C-P",
        "AC022-AP",
        "LINEARDRIVE",
        "LPV",
      ],
      partList: [
        "RURE IQ LED STRIPS",
        "ALUM PROFILE",
        "SS CLIPS",
        "LIN210",
        "60-24",
      ],
      productType: ["Brand", "Accessories"],
      currencyListType: ["SGD", "USD"],
      uomListType: ["unit", "kg", "gm", "mm", "nos", "meters"],
      discount_value: null,
      lump_discount_value: null,
      discount_type: 1,
      lump_discount_type: 2,
      discount_type_list: [
        { type: "%", value: 2 },
        { type: "$", value: 1 },
      ],
      selected_line_items2: [
        {
          group_name: "Group Name 1",
          children: [],
        },
        {
          group_name: "Aircon",
          children: [
            {
              brand: "JRLite",
              product: "Brand",
              model: "COVEFLEX1808",
              part: "RURE IQ LED STRIPS",
              cost_currency: "SGD",
              cost: 15.8,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: 5.0,
              item_cost: 33.44,
              uom: "meters",
              required_quantity: 20,
              proquantity: 20,
              margin: "30.00%",
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
          ],
          p_unit_selling: 1280.0,
          p_total_sales: "34.00 %",
          p_total_saling: 139520.0,
          p_total_cost: 8870.5,
        },
        {
          group_name: "Group Name 2",
          children: [],
        },
        {
          group_name: "Computer",
          children: [
            {
              brand: "MEANWELL",
              product: "Accessories",
              model: "AP-293C-P",
              part: "ALUM PROFILE",
              cost_currency: "SGD",
              cost: 3.85,
              flt: 1.2,
              xr: 1.5,
              gear: null,
              lamp: null,
              acc: null,
              item_cost: null,
              uom: null,
              quantity: null,
              margin: null,
              unit_selling: 47.77,
              pro_unit_selling: 47.77,
              item_selling: null,
              total_cost: 668.8,
              total_selling: 960.0,
              total_sales: 1.24,
              total: 0,
            },
          ],
          p_unit_selling: 1280.0,
          p_total_sales: "34.00 %",
          p_total_saling: 139520.0,
          p_total_cost: 8870.5,
        },
      ],
    };
  },
  // props: {
  //   relatedType: {
  //     type: Number,
  //     default: 3,
  //   },
  // },
  methods: {
    addParent() {
      this.firstParentAdded = true;
      this.selected_line_items2.push({
        group_name: "New Group Name",
        children: [],
      });
    },
    removeParent(parentIndex) {
      this.selected_line_items2.splice(parentIndex, 1);
    },
    addChild(parentIndex) {
      this.selected_line_items2[parentIndex].children.push({
        product: "New Child",
        model: null,
        part: null,
        cost: null,
        flt: null,
        xr: null,
        gear: null,
        lamp: null,
        acc: null,
        item_cost: null,
        margin: null,
        item_selling: null,
        total_cost: null,
        total_selling: null,
        total_salles: null,
        total: 0,
      });
    },
    removeChild(parentIndex, childIndex) {
      this.selected_line_items2[parentIndex].children.splice(childIndex, 1);
    },
    add_component() {
      this.selected_line_items2.push({
        group_name: "New Item",
        children: [
          {
            product: "New Child",
            model: null,
            part: null,
            cost: null,
            flt: null,
            xr: null,
            gear: null,
            lamp: null,
            acc: null,
            item_cost: null,
            margin: null,
            item_selling: null,
            total_cost: null,
            total_selling: null,
            total_sales: null,
            total: 0,
          },
        ],
        p_unit_selling: 1280.0,
        p_total_sales: "34.00 %",
        p_total_saling: 139520.0,
        p_total_cost: 8870.5,
      });
    },
    syncScrollFromBottom() {
      this.$refs.topScrollbar.scrollLeft =
        this.$refs.scrollContainer.scrollLeft;
    },
    syncScrollFromTop() {
      this.$refs.scrollContainer.scrollLeft =
        this.$refs.topScrollbar.scrollLeft;
    },
    handleDelete() {
      this.removeParent();
      this.showDeleteConfirmation = false;
    },
    handleCancel() {
      this.showDeleteConfirmation = false;
    },
    openDeleteConfirmation() {
      this.showDeleteConfirmation = true;
    },
  },
  mounted() {
    // Synchronize initial scroll position if needed
    this.$refs.topScrollbar.scrollLeft = this.$refs.scrollContainer.scrollLeft;
  },
  components: {
    Draggable,
    DeleteSmallConfirm,
  },
};
</script>
<style scoped>
.custom-grey-border .color-custom-blue:nth-child(1) {
  position: sticky;
  left: 0;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:nth-child(2) {
  position: sticky;
  left: 30px;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:nth-child(3) {
  position: sticky;
  left: 235px;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:nth-child(4) {
  position: sticky;
  left: 440px;
  background: #f5f5f5;
}
.custom-grey-border .color-custom-blue:last-child {
  position: sticky;
  right: 0px;
  background: #f5f5f5;
}

.line-item-drag .product_header .freeze-column:first-child {
  position: sticky;
  left: 0px;
  background: #e0f7fa !important;
  z-index: 9;
}
.line-item-drag .product_header .freeze-column:nth-child(2) {
  position: sticky;
  left: 30px;
  background: #e0f7fa !important;
  z-index: 9;
}
.line-item-drag .freeze-column:nth-child(1) {
  position: sticky;
  left: 0px;
  background: #fff !important;
  z-index: 9;
}

.line-item-drag .freeze-column:nth-child(2) {
  position: sticky;
  left: 30px;
  background: #fff !important;
  z-index: 9;
}
.line-item-drag .freeze-column:nth-child(3) {
  position: sticky;
  left: 230px;
  background: #fff !important;
  z-index: 9;
}
.line-item-drag .freeze-column:nth-child(4) {
  position: sticky;
  left: 430px;
  background: #fff !important;
  z-index: 9;
}
.line-item-drag .highlighted {
  background: antiquewhite;
}

.line-item-drag .product_header .last-child:last-child {
  position: sticky;
  right: 0px;
  z-index: 9;
  background: #e0f7fa;
}
.line-item-drag .last-child:last-child {
  position: sticky;
  right: 0px;
  z-index: 9;
  background: #fff;
}

.line-item-v2 .line-item-drag {
  padding-top: 0px;
  padding-bottom: 0px;
}
.line-item-v2 .line-item-drag .freeze-column {
  padding: 2px 0px;
}
.line-item-v2 li.line-item-drag:nth-child(even) {
  background: no-repeat !important;
}
.line-item-v2 li.line-item-drag.header_color .product_header {
  background: rgb(217 214 214 / 64%) !important;
}
.line-item-v2 li.line-item-drag.header_color .product_header .freeze-column {
  background: rgb(217 214 214 / 64%) !important;
}
.line-item-v2 li.line-item-drag.header_color .product_header .last-child {
  background: rgb(217 214 214 / 64%) !important;
}
/* scroll css */
.custom-grey-border {
  position: relative;
}

.scrollbar-wrapper {
  overflow: hidden;
}

.scrollbar {
  height: 10px; /* Same height as bottom scrollbar */
  overflow-x: scroll;
  background-color: transparent;
}

.scrollbar .scroll-content {
  height: 1px;
  background-color: transparent;
  width: 2697px;
}

.content-wrapper {
  height: auto; /* Adjust height as needed */
}

.scrollbar::-webkit-scrollbar {
  height: 10px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #ccc; /* Style scrollbar thumb */
  border-radius: 0px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
</style>
