<template>
  <div>
    <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
      <template v-slot:title>Create Opportunity </template>
      <template v-slot:body>
        <v-container>
          <v-layout>
            <v-row>
              <v-col md="12">
                <div>
                  <label class="font-weight-700 font-size-16 required"
                    >Opportunity Name
                  </label>
                  <v-text-field
                    v-model.trim="opportunityCreate.name"
                    dense
                    filled
                    color="cyan"
                    label="Opportunity Name"
                    :disabled="pageLoading"
                    :loading="pageLoading"
                    solo
                    flat
                  ></v-text-field>
                </div>
              </v-col>
              <v-col md="6">
                <div>
                  <label class="font-weight-700 font-size-16"
                    >Start Date
                  </label>
                  <DatePicker solo :placeholder="'Start Date *'" :default-date="
                  opportunityCreate.started_at "
                  v-model="opportunityCreate.started_at" mandatory: true />
                </div>
              </v-col>
              <v-col md="6">
                <div>
                  <label class="font-weight-700 font-size-16">End Date </label>
                  <DatePicker solo :placeholder="'End Date'" :default-date="
                  opportunityCreate.deadline "
                  :min-date="opportunityCreate.started_at"
                  v-model="opportunityCreate.deadline" mandatory: true />
                </div>
              </v-col>
              <v-col md="12">
                <div>
                  <label class="font-weight-700 font-size-16">Project </label>
                  <v-autocomplete
                    dense
                    color="cyan"
                    filled
                    id="project"
                    :items="projectList"
                    :disabled="formLoading"
                    :loading="formLoading"
                    v-model="opportunityCreate.project_name"
                    placeholder="Select Project"
                    solo
                    flat
                    item-color="cyan"
                    item-text="display_name"
                    item-value="id"
                    hide-details
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="'No Project Found.'"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-slot:append-item>
                      <v-divider class="mt-2"></v-divider>
                      <v-btn
                        class="mx-3"
                        depressed
                        @click="addProjectDialog = true"
                        ><v-icon color="blue">mdi-plus</v-icon
                        ><span class="blue--text"> Add New</span>
                      </v-btn>
                      <template v-if="false">
                        <v-row class="mx-0">
                          <v-col md="12">
                            <div>
                              <label
                                class="font-weight-700 font-size-16 required"
                                >Project Title
                              </label>
                              <v-text-field
                                v-model.trim="opportunityCreate.name"
                                dense
                                filled
                                color="cyan"
                                label="Project Name"
                                :disabled="pageLoading"
                                :loading="pageLoading"
                                solo
                                flat
                              ></v-text-field>
                            </div>
                          </v-col>
                          <v-col md="6">
                            <div>
                              <label class="font-weight-700 font-size-16"
                                >Start Date
                              </label>
                              <DatePicker solo :placeholder="'Start Date *'"
                              :default-date=" opportunityCreate.started_at "
                              v-model="opportunityCreate.started_at" mandatory:
                              true />
                            </div>
                          </v-col>
                          <v-col md="6">
                            <div>
                              <label class="font-weight-700 font-size-16"
                                >End Date
                              </label>
                              <DatePicker solo :placeholder="'End Date'"
                              :default-date=" opportunityCreate.deadline "
                              :min-date="opportunityCreate.started_at"
                              v-model="opportunityCreate.deadline" mandatory:
                              true />
                            </div>
                          </v-col>
                          <v-col md="12">
                            <v-btn
                              @click="addProjectDialog = false"
                              color="blue darken-4"
                              class="mx-3"
                              depressed
                              ><span class="white--text">Save</span>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </template>
                    </template>
                  </v-autocomplete>
                </div>
              </v-col>
              <v-col
                md="12"
                class="border mx-2"
                style="background-color: rgb(248 238 238)"
                v-if="addProjectDialog == true"
              >
                <v-row>
                  <v-col md="12">
                    <div>
                      <label class="font-weight-700 font-size-16 required"
                        >Project Title
                      </label>
                      <v-text-field
                        v-model.trim="opportunityCreate.project_title"
                        dense
                        filled
                        color="cyan"
                        label="Project Name"
                        :disabled="pageLoading"
                        :loading="pageLoading"
                        solo
                        flat
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col md="6">
                    <div>
                      <label class="font-weight-700 font-size-16"
                        >Project Start Date
                      </label>
                      <DatePicker solo :placeholder="'Start Date *'"
                      :default-date=" opportunityCreate.project_started_at "
                      v-model="opportunityCreate.project_started_at" mandatory:
                      true />
                    </div>
                  </v-col>
                  <v-col md="6">
                    <div>
                      <label class="font-weight-700 font-size-16"
                        >Project End Date
                      </label>
                      <DatePicker solo :placeholder="'End Date'" :default-date="
                      opportunityCreate.deadline "
                      :min-date="opportunityCreate.project_started_at"
                      v-model="opportunityCreate.deadline" mandatory: true />
                    </div>
                  </v-col>
                  <!-- <v-col md="12">
                    <v-btn
                      @click="addProjectDialog = false"
                      color="blue darken-4"
                      class="me-2"
                      depressed
                      ><span class="white--text">Save</span>
                    </v-btn>
                    <v-btn
                      depressed
                      @click="addProjectDialog = false"
                      color=""
                      class=""
                      ><span class="">Cancel</span>
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
            </v-row>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button white--text"
          color="cyan"
          v-on:click="$emit('close', true)"
        >
          Save
        </v-btn>
        <v-btn
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close', true)"
        >
          Cancel
        </v-btn>
      </template>
    </Dialog>
    <!-- <AddProjectDialog
      :dialog="addProjectDialog"
      v-on:close="addProjectDialog = false"
    /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import Dialog from "@/view/pages/partials/Dialog.vue";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
// import AddProjectDialog from "@/view/components/AddProjectDialog.vue";
export default {
  name: "opportunity",
  data() {
    return {
      pageLoading: false,
      formLoading: false,
      addProjectDialog: false,
      projectList: [],
      opportunityCreate: {
        name: null,
        project_name: null,
        project_title: null,
        started_at: null,
        project_started_at: null,
      },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Dialog,
    DatePicker,
    // AddProjectDialog,
  },

  computed: {
    ...mapGetters(["preventiveScheduleOutput", "prventiveRawScheduleOutput"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 50);
    },
  },
};
</script>
